const initialState = {
    analysis: []
  }
  
  const CarBigDataReducer = function (state = initialState, action) {
    switch (action.type) {
      case 'CREATE_ANALYSIS': {
        return {
          ...state,
          analysis: action.analysis

        }
      }
      default: {
        return { ...state }
      }
    }
  }
  
  export default CarBigDataReducer
  