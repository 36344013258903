const initialState = {
  processes: [],
  processesToday: [],
  processesThisWeek: [],
  processesThisMonth: [],
  processesByFilter: [],
  isUpdated: false
}

const HistoricoGeralReducer = function (state = initialState, action) {
  switch (action.type) {
    case 'SET_ALL_PROCESSES': {
      return {
        ...state,
        processes: action.processes,
        processesToday: action.processesToday,
        processesThisWeek: action.processesThisWeek,
        processesThisMonth: action.processesThisMonth,
        isUpdated: true
      }
    }
    case 'SORT_ALL_BY_DATE': {
      return {
        ...state,
        processes: action.processesByPeriod,
        processesByPeriod: action.processesByPeriod
      }
    }
    default: {
      return { ...state }
    }
  }
}

export default HistoricoGeralReducer
