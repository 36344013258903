const initialState = {
  rate: [],
  report: [],
  month: [],
  week: [],
  day: [],
  reportAdmin: [],
  ranking: [],
  reportHistory: [],
  isUpdated: false,
  reportToday: [],
  reportWeek: [],
  reportMonth: [],
  reportYear: [],
    error: null
}
const RelatorioReducer = function (state = initialState, action) {
  switch (action.type) {
    case 'SET_REPORT_TYPE': {
      return {
        ...state,
        [action.timeFrame]: action.report,
        isUpdated: true
      }
    }
    case 'SET_RATE': {
      return {
        ...state,
        rate: action.rate,
        isUpdated: true
      }
    }
    case 'SET_REPORT_HISTORY': {
      return {
        ...state,
        reportHistory: action.reportHistory,
        isUpdated: true
      }
    }
    case 'SET_TODAY_REPORT': {
      return {
        ...state,
        reportToday: action.reportToday,
        error: action.error, // Set error in the state
        isUpdated: true
      }
    }
    case 'SET_WEEK_REPORT': {
      return {
        ...state,
        reportWeek: action.reportWeek,
        isUpdated: true
      }
    }
    case 'SET_MONTH_REPORT': {
      return {
        ...state,
        reportMonth: action.reportMonth,
        isUpdated: true
      }
    }
    case 'SET_YEAR_REPORT': {
      return {
        ...state,
        reportYear: action.reportYear,
        isUpdated: true
      }
    }
    default: {
      return { ...state }
    }
  }
}


export default RelatorioReducer
