const initialState = {
  rate: [],
  month: [],
  week: [],
  day: [],
  ranking: [],
  reportHistory: [],
  isUpdated: false,
  reportToday: [],
  reportWeek: [],
  reportMonth: [],
  reportYear: [],
  error: null

}
const RelatorioAdminReducer = function (state = initialState, action) {
    switch (action.type) {  
    case 'SET_RATE': {
      return {
        ...state,
        rate: action.rate,
        isUpdated: true
      }
    }
    case 'SET_RANKING': {
      return {
        ...state,
        ranking: action.ranking,
        isUpdated: true
      }
    }
    case 'SET_REPORT_HISTORY': {
      return {
        ...state,
        reportHistory: action.reportHistory,
        isUpdated: true
      }
    }
    case 'SET_TODAY_REPORT_ADMIN': {
      return {
        ...state,
        reportTodayAdmin: action.reportTodayAdmin,
         error: action.error,
        isUpdated: true
      }
    }
    case 'SET_WEEK_REPORT_ADMIN': {
      return {
        ...state,
        reportWeekAdmin: action.reportWeekAdmin,
        isUpdated: true
      }
    }
    case 'SET_MONTH_REPORT_ADMIN': {
      return {
        ...state,
        reportMonthAdmin: action.reportMonthAdmin,
        isUpdated: true
      }
    }
    case 'SET_YEAR_REPORT_ADMIN': {
      return {
        ...state,
        reportYearAdmin: action.reportYearAdmin,
        isUpdated: true
      }
    }
    default: {
      return { ...state }
    }
            
    }
}


export default RelatorioAdminReducer